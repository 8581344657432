// Here you can add other styles

$black: #000000;
$black-50: #d3e0e9;
$primary: #292961;
$tertiary: #95aac9 !default;
$green: #00e396;
$info: #69d3f8;
$white: #ffffff;
$alert: #ffc48b;
$warning: #f2545b;


$medium-emphasis-inverse:  rgba($black, 1) !default;


.white {
	color: #fff !important;
}

.black {
	color: #000 !important;
}

.bg_white {
	background-color: #fff !important;
}

.bg_gray {
	background-color: #d7dbde !important;
}

.dropdown-item-block {
    display: block !important;
}

.custom-navgroup .nav-link {
	color: rgba($black, 1);
}

.custom-popover .popover-body {
	text-align: center;
}

.ball-marker {
	position: relative;
	display: inline-block;
	cursor: pointer;
	border: none;
	border-radius: 50%;
	height: 12px;
	width: 12px;
	padding: 0;
}

.ball-blue {
	background-color: rgb(0, 143, 251);
	color: rgb(0, 143, 251);
}

.ball-green {
	background-color: $green;
	color: rgb(0, 227, 150);
}

.ball-yellow {
	background-color: rgb(254, 176, 25);
	color: rgb(254, 176, 25);
}

.card:hover {
	box-shadow: 0 0 10px 2px rgba($color: $black, $alpha: 0.2);
	transition: all linear 0.3s;
}

.border-dotted {
	border-bottom: 3px dotted $black-50;
	margin-inline: 6px;
}

.Pendente {
	border: none;
	border-radius: 8px;
	background-color: $alert;
	$warning: #f2545b;
	font-size: 14px;
	text-align: center;
	padding: 4px 8px;
}

.Pago, .Carrinho {
	border: none;
	border-radius: 8px;
	background-color: $tertiary;
	font-size: 14px;
	text-align: center;
	padding: 4px 8px;
}

.Cancelado {
	border: none;
	border-radius: 8px;
	background-color: $warning;
	color: $white;
	font-size: 14px;
	text-align: center;
	padding: 4px 8px;
}

.row-hover:hover{
	background-color: rgba($secondary, 0.4);
}

.placeholder-white::-webkit-input-placeholder {
	color: $white;
}

.placeholder-white::-moz-placeholder {
	color: $white;
}

.placeholder-white::-ms-input-placeholder {
	color: $white;
}

.placeholder-white:-moz-placeholder {
	color: $white;
}

.timeline {
	border-radius: 50%;
	color: $white;
	height: 30px;
	width: 30px;
	padding: 0;
	margin-left: 10px;
}

.timelineGray {
	background: $tertiary;
}

.timelineInfo {
	background: $info;
}

.timelineGreen {
	background: $green;
}

.timelinePrimary {
	background: $primary;
}

.timeline::before{
    content: "";
    position: absolute;
	top: -16px;
	left: 36px;
	height: 18px;
	width: 1px;
	background-color: $tertiary;
}

.timeline::after{
	content: "";
	position: absolute;
	top: 30px;
	left: 36px;
	height: 18px;
	width: 1px;
	background-color: $tertiary;
}

.w-fitContent, .w-fitContent > label {
	width: fit-content;
	white-space: nowrap;
}

.px-0-sm {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.justify-center-sm {
	@media (max-width: 576px) {
		justify-content: center !important;
	}
}

.justify-center-md {
	@media (max-width: 768px) {
		justify-content: center !important;
	}
}

